import { applyMiddleware, compose, createStore } from 'redux'

import { createEpicMiddleware } from 'redux-observable'
import { createTransform, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import immutableTransform from 'redux-persist-transform-immutable'
import * as crypt from 'crypto-js'

import epicsRoots from './root.epics'
import createReducer from './root.reducer'

import TokenService from './../services/TokenService'

import { CONFIG } from 'config'

const SetTransform = createTransform(
    // Criptografa o persist.
    (inboundState, key) => {
        return crypt.AES.encrypt(inboundState, CONFIG.secret).toString()
    },

    // Descriptografa.
    (outboundState, key) => {
        const bytes = crypt.AES.decrypt(outboundState, CONFIG.secret)
        const data = JSON.parse(bytes.toString(crypt.enc.Utf8))
        const tokenService = new TokenService()
        tokenService.setToken(data.data.token)
        return JSON.stringify(data)
    },

    // define which reducers this transform gets called for.
    { whitelist: ['authsReducer'] },
)

const persistConfig = {
    transforms: [immutableTransform(), SetTransform],
    key: CONFIG.name,
    storage,
    blacklist: ['fuse', 'quickPanel'],
}

const persistedReducer = persistReducer(persistConfig, createReducer())

const epicMiddleware = createEpicMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middleware = applyMiddleware(epicMiddleware /* logger */)

const middle =
    process.env.NODE_ENV === 'production'
        ? middleware
        : composeEnhancers(middleware)

const configureStore = createStore(persistedReducer, middle)
epicMiddleware.run(epicsRoots)

const persistor = persistStore(configureStore)

export { configureStore, persistor }
