import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import styles from './styles.js'
import { QuizForm } from 'components/QuizForm'

class Home extends React.PureComponent {
    state = {
        step: 1,
    }

    onSubmit = (values, { setSubmitting }) => {
        setTimeout(() => {
            alert(JSON.stringify(values, null, 2))
            setSubmitting(false)
        }, 400)
    }

    nextStep = () => {
        const { step } = this.state
        this.setState({
            step: step + 1,
        })
    }

    render() {
        return <QuizForm />
    }
}

const mapStateToProps = (state) => ({
    authsReducer: state.authsReducer,
})

export default connect(
    mapStateToProps,
    null,
)(withStyles(styles, { withTheme: true })(Home))
