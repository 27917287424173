export default (theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        backgroundColor: '#542785',
        height: '100px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    containerHeader: {
        padding: '20px',
        display: 'flex',
        maxWidth: 1200,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            maxWidth: '95%',
        },
    },
    container: {
        padding: '10px',
        maxWidth: 1200,
        // backgroundColor: '#e7e1ed',
        margin: '20px 0',
        position: 'relative',
        display: 'block',
    },
    formContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    textInfo: {
        textAlign: 'left',
        padding: '0 20px',
        fontSize: '3.5rem',
        fonFamily:
            '"Myriad Pro",Myriad,MyriadPro,"Liberation Sans","Nimbus Sans L","Helvetica Neue",Helvetica,sans-serif',
        fontWeight: 300,
        letterSpacing: '1px',
    },
    textInput: {
        margin: '30px 10px',
        flexGrow: 2,
        width: '100%',
    },
    textInputHalf: {
        margin: '30px 10px',
        width: 'calc(50% - 20px)',
        flexGrow: 1,
    },
    button: {
        borderColor: '#753bbd',
        boxShadow: '0 8px 9px 0 rgba(71,33,112,.23)',
        position: 'relative',
        color: '#fff',
        background: 'linear-gradient(to right,#8542d6 0,#753bbd 100%)',
        borderRadius: 32,
        padding: '17px 24px 15px',
        fontFamily:
            '"Myriad Pro",Myriad,MyriadPro,"Liberation Sans","Nimbus Sans L","Helvetica Neue",Helvetica,sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '1.5px',
        lineHeight: '16px',
        textAlign: 'center',
        textTransform: 'uppercase',
        minWidth: 300,
        '&:hover': {
            backgroundColor: '#3f146d',
        },
        [theme.breakpoints.down('sm')]: {
            width: '95%',
            display: 'block',
            textAlign: 'center',
            margin: '0 auto',
        },
    },
    tips: {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'bold',
        width: 300,
        marginTop: '20px',
        textAlign: 'center',
    },
    maskedInput: {
        margin: '30px 10px',
        flexGrow: 2,
        width: '100%',
        backgroundColor: 'transparent',
        fontSize: '16px',
        '&::placeholder': {
            fontSize: '17px',
        },
    },
    error: {
        color: 'red',
        fontWeight: 'bold',
        fontFamily:
            '"Myriad Pro",Myriad,MyriadPro,"Liberation Sans","Nimbus Sans L","Helvetica Neue",Helvetica,sans-serif',
        margin: '-10px 0 0 10px',
        fontSize: '14px',
    },
})
