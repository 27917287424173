import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from '@lodash'

let redirect = false

class FuseAuthorization extends Component {
    constructor(props) {
        super(props)
        this.checkAuth()
    }

    componentDidUpdate(prevProps) {
        /**
         * If route is changed
         * Update auths
         */
        if (!_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
            this.checkAuth()
        }
    }

    checkAuth() {
        // console.log(this.props.authsReducer.get("token"));
        const token = this.props.authsReducer.get('token')
        if (token === null) {
            this.props.history.push({
                pathname: '/',
                state: { redirectUrl: this.props.location.pathname },
            })
        } else {
            this.props.history.push({
                pathname: '/dashboard',
            })
        }
    }

    shouldComponentUpdate(nextProps) {
        if (redirect) {
            redirect = false
            return false
        } else {
            return true
        }
    }

    render() {
        const { children } = this.props

        return <React.Fragment>{ children }</React.Fragment>
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

function mapStateToProps({ fuse, authsReducer }) {
    return {
        authsReducer: authsReducer,
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(FuseAuthorization),
)
