// A classe BaseLoopBackApi do arquivo base.service.js não permite importar os store do redux.
// Essa classe será utilizada pra armazenar o token do authReducer de forma singleton.

let instance = null

class TokenService {
    constructor() {
        if (!instance) {
            this.token = null
            instance = this
        }
        return instance
    }

    setToken = token => {
        this.token = token
    }

    getToken = () => {
        return this.token
    }
}

export default TokenService
