import 'babel-polyfill'
import 'typeface-muli'
import React from 'react'
import ReactDOM from 'react-dom'
import history from './history'
import './react-table-defaults'
import './styles/index.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import JssProvider from 'react-jss/lib/JssProvider'
import { create } from 'jss'
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles'
import registerServiceWorker from './registerServiceWorker'
import { Router } from 'react-router-dom'
import { FuseTheme } from '@fuse'
import jssExtend from 'jss-extend'
import Home from 'pages/Home'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore, persistor } from './redux/store'

import ReactGA from 'react-ga'

ReactGA.initialize('UA-131092353-2', {
    debug: false,
})
ReactGA.pageview(window.location.pathname + window.location.search)

history.listen((location) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
})

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
})

if (process.env.NODE_ENV !== 'development') {
    console.log = () => {}
}

jss.options.insertionPoint = document.getElementById('jss-insertion-point')
const generateClassName = createGenerateClassName()

ReactDOM.render(
    <JssProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={configureStore}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={history}>
                    <FuseTheme>
                        <ToastContainer />
                        <Home />
                    </FuseTheme>
                </Router>
            </PersistGate>
        </Provider>
    </JssProvider>,
    document.getElementById('root'),
)

registerServiceWorker()

if (module.hot) {
    module.hot.accept()
}
