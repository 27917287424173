import { combineReducers } from 'redux'
import fuse from './fuse'
import quickPanel from '../components/quickPanel/store/reducers'
import authsReducer from './auths/Auths.reducer'
import { reducer as formReducer } from 'redux-form'

const createReducer = (asyncReducers) =>
    combineReducers({
        fuse,
        quickPanel,
        authsReducer,
        form: formReducer,
        ...asyncReducers,
    })

export default createReducer
