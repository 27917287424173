/* ************************************ LOGIN  ************************************ */

export const LOGIN = 'LOGIN'
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN'
export const FAIL_LOGIN = 'FAIL_LOGIN'

export const LOGOUT = 'LOGOUT'

export const login = (identifier, password) => ({
    type: LOGIN,
    payload: {
        identifier: identifier,
        password: password,
    },
})

export const successLogin = (payload) => {
    return {
        type: SUCCESS_LOGIN,
        payload,
    }
}

export const failLogin = () => {
    return {
        type: FAIL_LOGIN,
    }
}

export const logout = () => {
    return {
        type: LOGOUT,
    }
}
