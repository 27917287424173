import React from 'react'
import { FormControl, FormLabel, Input } from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import { cepMask, cpfMask, phoneMask, rgMask } from 'components/Form/Masks'

const mask = (name) => {
    if (name === 'cpf') return cpfMask
    if (
        name === 'phone1' ||
        name === 'phone2' ||
        name === 'phone3' ||
        name === 'spouse_phone'
    )
        return phoneMask
    if (name === 'cep') return cepMask
    // if (name === 'rg') return rgMask
}

const TextMaskCustom = (props) => {
    const { inputRef, placeholder, ...other } = props
    return (
        <FormControl component={'fieldset'}>
            <FormLabel component="legend">{placeholder}</FormLabel>
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null)
                }}
                mask={mask(props.name)}
                placeholderChar={'\u2000'}
                showMask
            />
        </FormControl>
    )
}

export const MaskInput = ({
    field: { ...fields },
    form: { touched, errors, ...rest },
    mask,
    ...props
}) => (
    <Input
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
        {...props}
        {...fields}
    />
)
