import React from 'react'
import {
    validateStep1,
    validateStep2,
    validateStep22,
    validateStep24,
    validateStep25,
    validateStep27,
    validateStep28,
    validateStep3,
    validateStep4,
    validateStep5,
    validateStep51,
    validateStep6,
    validateStep7,
    validateStepMother,
    validateStepRG,
} from './Validation'
import {
    AddressInput,
    MaskInput,
    MaterialInput,
    RadioButton,
    RadioButtonGroup,
} from 'components/Form'
import { ErrorMessage, Field } from 'formik'
import { FormHelperText } from '@material-ui/core'
import InovaApi from 'services/InovaApi'
import { toast } from 'react-toastify'

export const Schema = [
    {
        step: 'first',
        title: 'Para começar, precisamos do seu CPF: *',
        form: {
            // validate: validateStep1,
            onSubmit: async (values, _this, ok) => {
                console.log('values', values, _this)
                try {
                    const cpf = values.cpf.replace(/[^\d]+/g, '')
                    if (cpf.length < 11) {
                        return toast.error(
                            'O CPF precisa ter pelo menos 11 caracteres',
                            {
                                position: toast.POSITION.TOP_RIGHT,
                            },
                        )
                    }
                    _this.setState({
                        step: 'first-1',
                        percent: 5,
                        form: {
                            ..._this.state.form,
                            cpf,
                        },
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        type="text"
                        name="cpf"
                        label="CPF"
                        component={MaskInput}
                        className={classes.maskedInput}
                        placeholder={'CPF *'}
                        required
                    />
                    <ErrorMessage
                        name="cpf"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'first-1',
        title: 'Agora, precisamos do seu nome completo: *',
        form: {
            validate: validateStep1,
            onSubmit: async (values, _this, ok) => {
                console.log('values', values, _this)
                try {
                    await _this.setState({ progress: true })
                    const res = await InovaApi.cpf(values.cpf)
                    console.log('cpf', res.data)
                    if (values.name.toUpperCase() !== res.data.name) {
                        return _this.setState({
                            step: 'fail',
                            percent: 0,
                            finish: true,
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                            cpfData: res.data,
                        })
                    }
                    await _this.setState({
                        step: 'second',
                        percent: 14,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                        cpfData: res.data,
                    })

                    InovaApi.post({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                } finally {
                    _this.setState({ progress: false })
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        type="text"
                        name="name"
                        label="Digite seu nome"
                        component={MaterialInput}
                        className={classes.textInput}
                        required
                    />
                    <ErrorMessage
                        name="name"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'fail',
        title:
            'Desculpe, não podemos concluir seu cadastro. Verifique seus dados e tente novamente.',
        percent: 0,
        form: {
            validate: validateStep1,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                _this.setState({
                    step: 'first',
                    percent: 0,
                    form: {
                        ..._this.state.form,
                        ...values,
                    },
                })
            },
            fields: (classes) => <React.Fragment />,
        },
    },
    {
        step: 'second',
        title: 'Agora, nos informe o seu telefone para contato:',
        percent: 14,
        form: {
            // validate: validateStep2,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    await _this.setState({
                        step: 'second-email',
                        percent: 28,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        placeholder="Telefone"
                        name="phone1"
                        type="text"
                        component={MaskInput}
                        className={classes.maskedInput}
                        required
                        mask={'(99) 99999-9999'}
                    />

                    <ErrorMessage
                        name="phone1"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'second-email',
        title: 'Agora, nos informe o seu email para contato:',
        percent: 14,
        form: {
            validate: validateStep2,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                const cpf = _this.state.form.cpf
                try {
                    await _this.setState({ progress: true })
                    const res = await InovaApi.phone(cpf)
                    const phone1 = values.phone1.replace(/[^\d]+/g, '')
                    console.log(phone1)

                    let filter = res.data.filter((item) => {
                        return item.phone === phone1
                    })

                    console.log(filter)

                    if (filter.length <= 0) {
                        if (_this.state.cpfData.rg === null) {
                            await _this.setState({
                                step: 'second-mother',
                                percent: 32,
                                form: {
                                    ..._this.state.form,
                                    ...values,
                                },
                            })
                            return InovaApi.put({
                                step: _this.state.step,
                                percent: _this.state.percent,
                                ...values,
                            })
                        }
                        await _this.setState({
                            step: 'second-rg',
                            percent: 28,
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'three',
                        percent: 32,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                } finally {
                    _this.setState({ progress: false })
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="Email"
                        type="email"
                        name="email"
                        component={MaterialInput}
                        className={classes.textInput}
                        required
                    />
                    <ErrorMessage
                        name="email"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'second-rg',
        title:
            'Precisamos de mais algumas informações. Informe o seu RG, por favor:',
        percent: 14,
        form: {
            validate: validateStepRG,
            onSubmit: async (values, _this, ok) => {
                console.log('values', values, _this)

                try {
                    const rgUser = _this.state.cpfData.rg
                    if (rgUser && rgUser === values.rg) {
                        await _this.setState({
                            step: 'second-2',
                            percent: 36,
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'fail',
                        percent: 0,
                        finish: true,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="RG"
                        name="rg"
                        type="text"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="rg"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'second-mother',
        title: 'Informe agora o nome completo de sua mãe:',
        percent: 14,
        form: {
            validate: validateStepMother,
            onSubmit: async (values, _this, ok) => {
                console.log('values', values, _this)
                try {
                    const filiation = _this.state.cpfData.filiation
                    if (
                        filiation &&
                        filiation === values.motherName.toUpperCase()
                    ) {
                        await _this.setState({
                            step: 'second-2',
                            percent: 36,
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'fail',
                        percent: 0,
                        finish: true,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="Nome completo da mãe"
                        name="motherName"
                        type="text"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="motherName"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'second-2',
        title:
            'Enviamos um SMS com um código para confirmar o número de celular informado. Por favor, informe abaixo o código recebido:',
        percent: 14,
        form: {
            validate: validateStep22,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                const code = 'INOVA999'
                try {
                    if (code !== values.smsCode.toUpperCase()) {
                        await _this.setState({
                            step: 'second-3',
                            percent: 38,
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'three',
                        percent: 38,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="Código recebido via SMS"
                        name="smsCode"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="smsCode"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'second-3',
        title:
            'Desculpe, não conseguimos fazer a verificação do número informado. Por favor, insira outro número de telefone:',
        percent: 14,
        form: {
            validate: validateStep1,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    const phone2 = values.phone2
                    if (phone2.length > 10) {
                        await _this.setState({
                            step: 'second-5',
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'second-4',
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        placeholder="Telefone 2"
                        name="phone2"
                        component={MaskInput}
                        className={classes.maskedInput}
                    />
                    <ErrorMessage
                        name="phone2"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'second-4',
        title:
            'Enviamos um SMS com um código para confirmar o número de celular informado. Por favor, informe abaixo o código recebido',
        percent: 14,
        form: {
            validate: validateStep24,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    const code = 'INOVA999'
                    if (code !== values.smsCode2.toUpperCase()) {
                        await _this.setState({
                            step: 'second-6',
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'three',
                        percent: 38,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    return InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="Código recebido via SMS"
                        name="smsCode2"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="smsCode2"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'second-5',
        title:
            'Como o número informado não foi um celular, em alguns instantes, ligaremos para você e informaremos um código de verificação. Por favor, informe abaixo o código recebido:',
        percent: 14,
        form: {
            validate: validateStep25,
            onSubmit: async (values, _this, ok) => {
                console.log('values', values, _this)
                try {
                    const code = 'INOVA999'
                    if (code !== values.callCode2.toUpperCase()) {
                        await _this.setState({
                            step: 'second-6',
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'three',
                        percent: 38,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="Código recebido via Ligação"
                        name="callCode2"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="callCode2"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'second-6',
        title:
            'Desculpe, não conseguimos fazer a verificação do número informado. Por favor, insira outro número de telefone:',
        percent: 14,
        form: {
            validate: validateStep1,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    const phone3 = values.phone3
                    if (phone3.length > 10) {
                        await _this.setState({
                            step: 'second-7',
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'second-8',
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        placeholder="Telefone 3"
                        name="phone3"
                        component={MaskInput}
                        className={classes.maskedInput}
                        mask={'(99) 99999-9999'}
                    />
                    <ErrorMessage
                        name="phone3"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'second-7',
        title:
            'Enviamos um SMS com um código para confirmar o número de celular informado. Por favor, informe abaixo o código recebido:',
        percent: 14,
        form: {
            validate: validateStep27,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    const code = 'INOVA999'
                    if (code !== values.smsCode3.toUpperCase()) {
                        await _this.setState({
                            step: 'fail',
                            percent: 0,
                            finish: true,
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'three',
                        percent: 38,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="Código recebido via SMS"
                        name="smsCode3"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="smsCode3"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'second-8',
        title:
            'Como o número informado não foi um celular, em alguns instantes, ligaremos para você e informaremos um código de verificação. Por favor, informe abaixo o código recebido:',
        percent: 14,
        form: {
            validate: validateStep28,
            onSubmit: async (values, _this, ok) => {
                console.log('values', values, _this)
                try {
                    const code = 'INOVA999'
                    if (code !== values.callCode3.toUpperCase()) {
                        await _this.setState({
                            step: 'fail',
                            percent: 0,
                            finish: true,
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'three',
                        percent: 38,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="Código recebido via Ligação"
                        name="callCode3"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="callCode3"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'three',
        title: 'Estamos indo bem. Vamos agora cadastrar o seu endereço:',
        form: {
            onSubmit: async (values, _this) => {
                console.log('three', values, _this)
                try {
                    values = {
                        ...values,
                        ...values.address,
                    }
                    delete values.address
                    console.log('address', values)
                    const address = values.address_resume
                    if (address === undefined || address === null) {
                        return toast.error(
                            'O endereço precisa ser selecionado.',
                            {
                                position: toast.POSITION.TOP_RIGHT,
                            },
                        )
                    }
                    await _this.setState({
                        step: 'five',
                        percent: 42,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="Digite seu endereço completo com número"
                        name="address"
                        component={AddressInput}
                        className={classes.maskedInput}
                    />
                    <ErrorMessage
                        name="address"
                        component={FormHelperText}
                        className={classes.error}
                    />
                    <Field
                        label="Complemento"
                        name="address_complement"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'five',
        title: 'Informe agora o seu estado civil:',
        form: {
            validate: validateStep5,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    if (
                        values.matrial_status === 'casado' ||
                        values.matrial_status === 'morando-junto'
                    ) {
                        await _this.setState({
                            step: 'five-spouseData',
                            percent: 60,
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'six',
                        percent: 70,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    return InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <RadioButtonGroup id="matrial_status">
                        <Field
                            id="solteiro"
                            name="matrial_status"
                            component={RadioButton}
                            label="Solteiro(a)"
                        />
                        <Field
                            id="casado"
                            component={RadioButton}
                            label="Casado(a)"
                            name="matrial_status"
                        />
                        <Field
                            id="viuvo"
                            component={RadioButton}
                            label="Viuvo(a)"
                            name="matrial_status"
                        />
                        <Field
                            id="divorciado"
                            component={RadioButton}
                            label="Divorciado(a)"
                            name="matrial_status"
                        />
                        <Field
                            id="morando-junto"
                            component={RadioButton}
                            label="Morando junto"
                            name="matrial_status"
                        />
                    </RadioButtonGroup>
                </React.Fragment>
            ),
        },
    },
    {
        step: 'five-spouseData',
        title: 'Preencher dados do cônjuge ',
        form: {
            validate: validateStep4,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    if (values.spouseData === 'yes') {
                        await _this.setState({
                            step: 'five-1',
                            percent: 76,
                            form: {
                                ..._this.state.form,
                                ...values,
                            },
                        })
                        return InovaApi.put({
                            step: _this.state.step,
                            percent: _this.state.percent,
                            ...values,
                        })
                    }
                    await _this.setState({
                        step: 'six',
                        percent: 76,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <RadioButtonGroup id="spouseData" label="Preencher dados">
                        <Field
                            component={RadioButton}
                            name="spouseData"
                            id="yes"
                            label="Sim"
                        />
                        <Field
                            component={RadioButton}
                            name="spouseData"
                            id="no"
                            label="Não"
                        />
                    </RadioButtonGroup>
                </React.Fragment>
            ),
        },
    },
    {
        step: 'five-1',
        title: 'Por favor, informe os dados do seu conjuge:',
        form: {
            validate: validateStep51,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    await _this.setState({
                        step: 'six',
                        percent: 84,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="Nome completo"
                        name="spouse_name"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="spouse_name"
                        component={FormHelperText}
                        className={classes.error}
                    />
                    <Field
                        placeholder="Telefone Conjuge"
                        name="spouse_phone"
                        component={MaskInput}
                        className={classes.maskedInput}
                        mask={'(99) 99999-9999'}
                    />
                    <ErrorMessage
                        name="spouse_phone"
                        component={FormHelperText}
                        className={classes.error}
                    />
                    <Field
                        label="Email"
                        name="spouse_email"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="spouse_email"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'six',
        title: 'Vamos agora cadastrar uma senha de acesso:',
        form: {
            validate: validateStep6,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    await _this.setState({
                        step: 'seven',
                        percent: 92,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <Field
                        autoFocus={true}
                        label="Senha"
                        name="password"
                        type="password"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="password"
                        component={FormHelperText}
                        className={classes.error}
                    />
                    <Field
                        label="Confirmar senha"
                        name="passwordConfirm"
                        type="password"
                        component={MaterialInput}
                        className={classes.textInput}
                    />
                    <ErrorMessage
                        name="passwordConfirm"
                        component={FormHelperText}
                        className={classes.error}
                    />
                </React.Fragment>
            ),
        },
    },
    {
        step: 'seven',
        title: 'Termos de Uso de Pré-cadastro:',
        form: {
            validate: validateStep7,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    await _this.setState({
                        step: 'eight',
                        finish: true,
                        percent: 100,
                        form: {
                            ..._this.state.form,
                            ...values,
                        },
                    })
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => (
                <React.Fragment>
                    <RadioButtonGroup id="accept_terms">
                        <Field
                            id="yes"
                            name="accept_terms"
                            component={RadioButton}
                            label="Aceito os Termos de Uso de Pré-Cadastro e as Normativas Eudora"
                        />
                    </RadioButtonGroup>
                </React.Fragment>
            ),
        },
    },
    {
        step: 'eight',
        title:
            'Parabéns! Seu cadastro foi concluído com sucesso. Em breve você receberá uma mensagem com algumas informações relacionadas ao seu cadastro no email informado no cadastro.',
        form: {
            validate: validateStep7,
            onSubmit: async (values, _this) => {
                console.log('values', values, _this)
                try {
                    InovaApi.put({
                        step: _this.state.step,
                        percent: _this.state.percent,
                        ...values,
                    })
                } catch (error) {
                    InovaApi.handleError(error)
                }
            },
            fields: (classes) => <React.Fragment />,
        },
    },
]
