export default (theme) => ({
    breadcrumb: {
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        padding: '10px',
        margin: '10px',
    },
    breadcrumb_item: {
        fontSize: '16px',
        color: '#fff',
    },
    icon: {
        position: 'relative',
        top: '5px',
        marginRight: '5px',
        color: '#fff',
    },
    lastIcon: {
        position: 'relative',
        top: '5px',
        marginRight: '5px',
        color: '#fff',
    },
    title: {
        color: '#fff',
    },
    lastTitle: {
        color: 'white',
    },
    separador: {
        margin: '0 15px',
        color: 'white',
    },
})
