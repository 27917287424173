import React from 'react'
import { FormControlLabel, Radio } from '@material-ui/core'

// Radio input
export const RadioButton = ({
    field: { name, value, onChange, onBlur },
    id,
    label,
    className,
    ...props
}) => {
    return (
        <div>
            <FormControlLabel
                name={name}
                id={id}
                type="radio"
                value={id}
                checked={id === value}
                onChange={onChange}
                onBlur={onBlur}
                control={<Radio style={{ color: '#542785' }} />}
                label={label}
                {...props}
            />
        </div>
    )
}

// Radio group
export const RadioButtonGroup = ({
    // value,
    // error,
    // touched,
    // id,
    // label,
    // className,
    children,
}) => {
    return (
        <div>
            <fieldset>{children}</fieldset>
        </div>
    )
}
