import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import styles from './styles'
import { Button, Grid, LinearProgress, Typography } from '@material-ui/core'
import { Schema } from './schema'
import _ from 'lodash'
import { Form } from 'formik'
import FormikWrapper from 'components/Form/Formik'
import AppBar from 'components/AppBar'
import { JsonView } from 'components'
import './style.css'
import ReactGA from 'react-ga'

class Component extends React.Component {
    state = {
        // step: 'three',
        step: Schema[0].step,
        form: {},
        percent: 0,
        progress: false,
        finish: false,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.step !== this.state.step) {
            ReactGA.set({ page: prevState.step })
            ReactGA.pageview(prevState.step)
        }
    }

    renderSchema = () => {
        const { classes } = this.props
        const { step, progress } = this.state
        const item = _.find(Schema, { step })

        return (
            <React.Fragment>
                <Grid container spacing={24} className={classes.container}>
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.textInfo}
                    >
                        {item.title}
                    </Typography>
                    {progress ? (
                        <LinearProgress
                            classes={{
                                colorPrimary: '#542785',
                                barColorPrimary: '#542785',
                            }}
                        />
                    ) : null}
                    <Grid
                        item
                        xs={12}
                        className={
                            progress
                                ? classes.formContentDisabled
                                : classes.formContent
                        }
                    >
                        <FormikWrapper
                            onSubmit={(values) =>
                                item.form.onSubmit(values, this)
                            }
                            validate={item.form.validate}
                        >
                            <Form className={classes.formContent}>
                                {item.form.fields(classes)}
                                {!this.state.finish ? (
                                    <div className={classes.rowButton}>
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            type="submit"
                                        >
                                            Continuar
                                        </Button>
                                        <span className={classes.tips}>
                                            ou pressione Enter
                                        </span>
                                    </div>
                                ) : null}
                            </Form>
                        </FormikWrapper>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    render() {
        const { classes, debug } = this.props
        const { percent, progress } = this.state
        return (
            <div className={classes.root}>
                <AppBar percent={percent} />
                {this.renderSchema()}
                {debug ? <JsonView json={this.state} /> : null}
            </div>
        )
    }
}

export const QuizForm = withStyles(styles, { withTheme: true })(Component)
