import React from 'react'
import { Formik } from 'formik'

const FormikWrapper = ({
    onSubmit,
    children,
    validate,
    initialValues,
    ...props
}) => (
    <Formik
        validationSchema={validate}
        onSubmit={onSubmit}
        initialValues={{
            cpf: '',
            name: '',
            phone1: '',
            phone2: '',
            phone3: '',
            email: '',
            rg: '',
            motherName: '',
            smsCode: '',
            smsCode2: '',
            smsCode3: '',
            callCode: '',
            callCode2: '',
            callCode3: '',
            address: '',
            complement: '',
            number: '',
            neighborhood: '',
            city: '',
            state: '',
            reference: '',
            address_complement: '',
            spouseData: '',
            matrial_status: '',
            spouse_name: '',
            spouse_email: '',
            spouse_phone: '',
            password: '',
            passwordConfirm: '',
            accept_terms: '',
        }}
        render={({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
            isSubmitting,
        }) => children}
    />
)

export default FormikWrapper
