import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const styles = {
    root: {
        width: '250px',
    },
    percent: {
        textAlign: 'right',
        color: 'white',
        fontFamily:
            '"Myriad Pro",Myriad,MyriadPro,"Liberation Sans","Nimbus Sans L","Helvetica Neue",Helvetica,sans-serif',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: 1.5,
        marginBottom: '10px',
    },
}

class LinearDeterminate extends React.Component {
    state = {
        completed: 0,
    }

    progress = () => {
        const { completed } = this.state
        if (completed === 100) {
            this.setState({ completed: 0 })
        } else {
            const diff = Math.random() * 10
            this.setState({ completed: Math.min(completed + diff, 100) })
        }
    }

    render() {
        const { classes, variant, value } = this.props
        return (
            <div className={classes.root}>
                <div className={classes.percent}>{value}% completo</div>
                <LinearProgress variant={variant} value={value} />
            </div>
        )
    }
}

export default withStyles(styles)(LinearDeterminate)
