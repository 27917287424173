import React, { Component } from 'react'
import FuseNavVerticalGroup from './vertical/FuseNavVerticalGroup'
import FuseNavVerticalCollapse from './vertical/FuseNavVerticalCollapse'
import FuseNavVerticalItem from './vertical/FuseNavVerticalItem'
import FuseNavHorizontalGroup from './horizontal/FuseNavHorizontalGroup'
import FuseNavHorizontalCollapse from './horizontal/FuseNavHorizontalCollapse'
import FuseNavHorizontalItem from './horizontal/FuseNavHorizontalItem'
import { Divider, Hidden, List } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles/index'
import classNames from 'classnames'

const propTypes = {
    navigation: PropTypes.array.isRequired,
}

const defaultProps = {
    layout: 'vertical',
}

const styles = theme => ({
    nav: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
})

class FuseNavigation extends Component {
    render() {
        const { navigation, layout, active, classes } = this.props

        const verticalNav = (
            <List className="whitespace-no-wrap">
                { navigation.map(item => (
                    <React.Fragment key={ item.id }>
                        { item.type === 'group' && (
                            <FuseNavVerticalGroup
                                item={ item }
                                nestedLevel={ 0 }
                                active={ active }
                            />
                        ) }

                        { item.type === 'collapse' && (
                            <FuseNavVerticalCollapse
                                item={ item }
                                nestedLevel={ 0 }
                                active={ active }
                            />
                        ) }

                        { item.type === 'item' && (
                            <FuseNavVerticalItem
                                item={ item }
                                nestedLevel={ 0 }
                                active={ active }
                            />
                        ) }

                        { item.type === 'divider' && <Divider className="my-16"/> }
                    </React.Fragment>
                )) }
            </List>
        )

        const horizontalNav = (
            <List className={ classNames(classes.nav, 'whitespace-no-wrap p-0') }>
                { navigation.map(item => (
                    <React.Fragment key={ item.id }>
                        { item.type === 'group' && (
                            <FuseNavHorizontalGroup item={ item } nestedLevel={ 0 }/>
                        ) }

                        { item.type === 'collapse' && (
                            <FuseNavHorizontalCollapse item={ item } nestedLevel={ 0 }/>
                        ) }

                        { item.type === 'item' && (
                            <FuseNavHorizontalItem item={ item } nestedLevel={ 0 }/>
                        ) }

                        { item.type === 'divider' && <Divider className="my-16"/> }
                    </React.Fragment>
                )) }
            </List>
        )

        if (navigation.length > 0) {
            switch (layout) {
                case 'horizontal': {
                    return (
                        <React.Fragment>
                            <Hidden lgUp>{ verticalNav }</Hidden>
                            <Hidden smDown>{ horizontalNav }</Hidden>
                        </React.Fragment>
                    )
                }
                case 'vertical':
                default: {
                    return verticalNav
                }
            }
        } else {
            return ''
        }
    }
}

FuseNavigation.propTypes = propTypes
FuseNavigation.defaultProps = defaultProps

export default withStyles(styles, { withTheme: true })(
    withRouter(FuseNavigation),
)
