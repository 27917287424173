export const navigation = [
    {
        id: 'home',
        title: 'Dashboard',
        type: 'group',
        icon: 'equalizer',
        url: '/',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/',
            },
        ],
    },
]
