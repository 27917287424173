const settings = {
    layout: {
        style: 'layout2',
        config: {
            scroll: 'content',
            navbar: {
                display: true,
                folded: true,
                position: 'left',
            },
            toolbar: {
                display: false,
                style: 'fixed',
                position: 'below',
            },
            footer: {
                display: false,
                style: 'fixed',
                position: 'below',
            },
            leftSidePanel: {
                display: false,
            },
            rightSidePanel: {
                display: false,
            },
            mode: 'fullwidth',
        },
    },
    customScrollbars: true,
    theme: {
        main: 'default',
        navbar: 'mainThemeDark',
        toolbar: 'mainThemeLight',
        footer: 'mainThemeDark',
    },
}

export default settings
