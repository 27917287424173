import React from 'react'
import { FormControl, FormLabel } from '@material-ui/core'
import Geosuggest from 'react-geosuggest'
import Gmap from 'services/GMap'

export const AddressInput = ({
    field: { name, ...fields },
    form: { touched, errors, setFieldValue, ...rest },
    label,
}) => (
    <FormControl component={'fieldset'} style={{ width: '100%' }}>
        <FormLabel component="legend">{label}</FormLabel>
        <Geosuggest
            country={'br'}
            ignoreEnter={false}
            placeholder={'Digite aqui o endereço'}
            onSuggestSelect={(data) => {
                console.log(data)
                const map = new Gmap().parse(data.gmaps)
                console.log('map', map)
                setFieldValue(name, map)
            }}
        />
    </FormControl>
)
