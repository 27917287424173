import React from 'react'
import { TextField } from '@material-ui/core'

export const MaterialInput = ({
    field: { ...fields },
    form: { touched, errors, ...rest },
    ...props
}) => (
    <React.Fragment>
        <TextField
            {...props}
            {...fields}
            error={Boolean(touched[fields.name] && errors[fields.name])}
        />
    </React.Fragment>
)
