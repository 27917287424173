import * as Actions from './actions/fuse/index'
import { navigation as initialState } from 'config/navigation'

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_NAVIGATION: {
            return [
                ...state,
            ]
        }
        case Actions.SET_NAVIGATION: {
            return [
                ...action.navigation,
            ]
        }
        case Actions.RESET_NAVIGATION: {
            return [
                ...initialState,
            ]
        }
        default: {
            return state
        }
    }
}
