import React from 'react'
import BrM from 'br-masks'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles/index'

const styles = (theme) => ({
    link: {
        color: '#424242 !important',
        '&:hover': {
            color: '#E5B218 !important',
            textDecoration: 'none !important',
            fontWeight: 'bold !important',
        },
    },
})

const CPF_CNPJ_Format = ({ value, classes }) => (
    <Link to={ `/client/${ value }` } className={ classes.link }>
        { BrM.cpfCnpj(value + '') }
    </Link>
)

export const CPF_CNPJ = withStyles(styles)(CPF_CNPJ_Format)
