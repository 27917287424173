import * as Yup from 'yup'

export const validateStep1 = Yup.object().shape({
    name: Yup.string()
        .min(3, 'O nome precisa ter pelo menos 3 caracteres!')
        .required('O nome é obrigatório'),
})

export const validateStep2 = Yup.object().shape({
    email: Yup.string()
        .email('O email informado não é válido!')
        .required('O email é obrigatório'),
})

export const validateStepRG = Yup.object().shape({
    rg: Yup.string().min(5, 'O RG precisa ter pelo menos 5 caracteres!'),
})

export const validateStepMother = Yup.object().shape({
    motherName: Yup.string()
        .min(3, 'O nome precisa ter pelo menos 3 caracteres!')
        .max(50, 'O nome não pode ter mais de 50 caracteres!')
        .required('O nome é obrigatório'),
})

export const validateStep22 = Yup.object().shape({
    smsCode: Yup.string()
        .min(8, 'O Código precisa ter pelo menos 8 caracteres!')
        .max(8, 'O Código não pode ter mais de 8 caracteres!')
        .required('O Código é obrigatório'),
})

export const validateStep23 = Yup.object().shape({})

export const validateStep24 = Yup.object().shape({
    smsCode2: Yup.string()
        .min(8, 'O Código precisa ter pelo menos 8 caracteres!')
        .max(8, 'O Código não pode ter mais de 8 caracteres!')
        .required('O Código é obrigatório'),
})

export const validateStep25 = Yup.object().shape({
    callCode2: Yup.string()
        .min(8, 'O Código precisa ter pelo menos 8 caracteres!')
        .max(8, 'O Código não pode ter mais de 8 caracteres!')
        .required('O Código é obrigatório'),
})

export const validateStep27 = Yup.object().shape({
    smsCode3: Yup.string()
        .min(8, 'O Código precisa ter pelo menos 8 caracteres!')
        .max(8, 'O Código não pode ter mais de 8 caracteres!')
        .required('O Código é obrigatório'),
})

export const validateStep28 = Yup.object().shape({
    callCode3: Yup.string()
        .min(8, 'O Código precisa ter pelo menos 8 caracteres!')
        .max(8, 'O Código não pode ter mais de 8 caracteres!')
        .required('O Código é obrigatório'),
})

// export const validateStep3 = Yup.object().shape({
//     address: Yup.object().shape({
//         description: Yup.string().required('O Endereço é obrigatório'),
//     }),
// })

export const validateStep4 = Yup.object().shape({
    spouseData: Yup.string().required('Uma opção precisa ser escolhida!'),
})

export const validateStep5 = Yup.object().shape({
    matrial_status: Yup.string().required('Uma opção precisa ser escolhida!'),
})

export const validateStep51 = Yup.object().shape({
    spouse_name: Yup.string()
        .min(2, 'O Nome precisa ter pelo menos 2 caracteres!')
        .max(50, 'O Nome não pode ter mais de 50 caracteres!')
        .required('O Bairro é obrigatório'),
    spouse_email: Yup.string()
        .email('O email informado não é válido!')
        .required('O email é obrigatório'),
})

export const validateStep6 = Yup.object().shape({
    password: Yup.string()
        .min(8, 'A Senha precisa ter pelo menos 8 caracteres!')
        .required('A Senha é obrigatório'),
    passwordConfirm: Yup.string()
        .min(8, 'A Senha precisa ter pelo menos 8 caracteres!')
        .oneOf(
            [Yup.ref('password'), null],
            'As senhas não podem ser diferentes!',
        )
        .required('A Senha é obrigatório'),
})

export const validateStep7 = Yup.object().shape({
    accept_terms: Yup.string().required('É preciso aceitar os termos!'),
})
