import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import styles from './styles'

class InovaGridComponent extends React.Component {
    render() {
        const { classes } = this.props
        return (
            <div className={ classes.root }>
                Testando componente { this.props.name }
            </div>
        )
    }
}

export const InovaGrid = withStyles(styles, { withTheme: true })(
    InovaGridComponent,
)
