import React from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import styles from './styles'

class Component extends React.Component {
    static defaultProps = {
        json: {},
    }

    render() {
        const { classes, json } = this.props
        return (
            <div className={classes.root}>
                <pre>{JSON.stringify(json, null, 2)}</pre>
            </div>
        )
    }
}

export const JsonView = withStyles(styles, { withTheme: true })(Component)
