import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { AppBar, Grid } from '@material-ui/core'
import Progress from 'components/Progress'
import styles from './styles.js'

const AppBarContainer = ({ classes, percent }) => (
    <AppBar position="static" color="default" className={classes.header}>
        <Grid container spacing={24} className={classes.containerHeader}>
            <a href="https://cadastro.eudora.com.br/">
                <img
                    src="assets/images/eudora_logo.png"
                    alt="Logo Eudora"
                    style={{ height: '90px' }}
                />
            </a>
            {percent ? (
                <Progress variant="determinate" value={percent} />
            ) : null}
        </Grid>
    </AppBar>
)

export default withStyles(styles, { withTheme: true })(AppBarContainer)
