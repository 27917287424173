export default class GMap {
    _data
    address

    loading = true

    componentForm = {
        street_number: 'long_name',
        //number
        route: 'long_name',
        //street
        locality: 'long_name',
        // district
        sublocality_level_1: 'long_name',
        // district
        neighborhood: 'long_name',
        //state
        political: 'long_name',
        //state
        administrative_area_level_2: 'long_name',
        //state
        country: 'long_name',
        //country
        postal_code: 'long_name', //zipcode
    }
    componentFormName = {
        street_number: 'address_number',
        //number
        route: 'address_street',
        //state
        sublocality_level_1: 'address_district',
        //street
        administrative_area_level_2: 'address_city',
        // district
        administrative_area_level_1: 'address_state',
        //state
        country: 'address_country',
        //country
        postal_code: 'address_zipcode',
        //zipcode
        neighborhood: 'address_district',
    }
    autocomplete = {
        query: '',
    }

    parse = (location) => {
        if (!location) {
            return {}
        }
        let address = {
            address_latitude: location.geometry.location.lat(),
            address_longitude: location.geometry.location.lng(),
        }

        for (let i = 0; i < location.address_components.length; i++) {
            let addressType = location.address_components[i].types[0]
            if (this.componentForm[addressType]) {
                address[this.componentFormName[addressType]] =
                    location.address_components[i][
                        this.componentForm[addressType]
                    ]
            }
        }
        if (location.name) {
            address['address_place'] = location.name
        }
        address['address_resume'] = `${address['address_street']}, ${
            address['address_number']
        } - ${address['address_city']} - ${address['address_state']} - ${
            address['address_country']
        }`
        return address
    }
}
